<template>
  <div class="inpatient_container">
    <div class="order_main">
      <el-table v-loading="loading" :data="recipedetailData" border="" height="100%" style="width: 100%" @row-click="on_select">
        <el-table-column label="药品名称" prop="itemName"></el-table-column>
        <el-table-column label="价格/元" prop="itemTotalFee"></el-table-column>
        <el-table-column label="数量" prop="itemNumber"></el-table-column>
        <el-table-column label="单位" prop="itemUnit"></el-table-column>
      </el-table>
      <el-button class="print" type="primary" @click.native="print">打印缴费凭条</el-button>
    </div>
    <el-button class="back" type="primary" @click.native="back">返回</el-button>
    <el-button class="home" type="primary" @click.native="home">首页</el-button>
  </div>
</template>

<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost, formPost } from '@/baseAPI'

export default {
  data() {
    return {
      recipedetailData: [],
      loading: false,
      patCardNo: '',
      query: {},
      printData: {}
    }
  },
  created() {
    this.query = this.$route.query
    this.date = this.formatTime(-500)
    this.end = this.formatTime(0)
    this.getOrderList()
  },
  methods: {
    async getOrderList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await jsonPost('/outpatient/getClinicPaidRecordsDetail', {
        agtOrdNum: '',
        invoId: this.query.invoId,
        orderNum: this.query.orderNum,
        patCardNo: this.query.patCardNo,
        patCardType: '1',
        visitType: '1'
      })
      if (res.code === 0) {
        this.recipedetailData = res.data.item
        this.printData = res.data
        loading.close()
      } else {
        loading.close()
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    // 门诊缴费凭条
    print() {
      let _this = this
      const params = {
        orderNum: this.query.orderNum,
        name: encodeURI(this.printData.patName),
        patCardNo: this.query.patCardNo,
        cost: this.printData.totalAmout,
        outTradeNo: this.printData.outTradeNo || '无',
        guideListInfo: encodeURI(this.printData.guideListInfo),
        invoiceUrl: this.printData.invoiceUrl,
        deptName: encodeURI(this.printData.deptName),
        doctorName: encodeURI(this.printData.doctorName),
        date: this.query.date,
        budgeting: this.query.budgeting || 0,
        medicalInsurance: this.query.medicalInsurance || 0
      }
      Promise.all([_this.$api.printOut(params)]).then(data => {})
    },
    home() {
      this.$router.push('/home')
    },
    back() {
      this.$router.back()
    },
    on_select(row) {
      console.log('row', row)
    },
    formatTime(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear() //年
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 //月
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate() //日
      return [y, m, d].map(this.padStartConvert).join('-')
    },
    padStartConvert(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    }
  }
}
</script>

<style lang="less">
.inpatient_container {
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 30%;
}

.order_main {
  margin: 0 auto;
  width: 90%;
  height: 70%;
}

.order_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.order_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
  background-clip: padding-box !important;
}

.print {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 520px;
  right: 100px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 300px;
  font-size: 26px !important;
}
</style>
